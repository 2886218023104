import React, {useRef} from 'react';
import './App.css';
import {Box, Flex, useInterval} from "@chakra-ui/react";

import Gummies from "./Assets/gummies-logo1.jpeg"
//import Sunrise from "./assets/HomeCannaFlowersm.jpg"
import 'video-react/dist/video-react.css';
//import { Player } from 'video-react';
//import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'
import Header from "./Components/Header";
import Footer from './Components/Footer';


function App() {
    const videoRef = useRef<HTMLVideoElement>(null);

    const playVid = () => {
        videoRef.current?.play();
    };

    const pauseVid = () => {
        videoRef.current?.pause();
    };

    useInterval(() => {
        //if (videoRef?.current?.isConnected) {
            playVid()
//        }
    }, 2000)


    return (
        <Flex style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            flexDirection: "column",
            //border: "1px dashed red",
            position: "relative",
        }}>


            <Flex style={{
                width: "100%",
                minHeight: "100vh",
                overflow: "auto",

                flexDirection: "column",
                //alignItems: "center",
                justifyContent: "flex-start",
                //border: "1px dashed green",
                //backgroundImage: `url(${Gummies})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                //position: "relative",

            }}>
                <Header />
                <video ref={videoRef} loop={true}
                       autoPlay={true}
                       width={"100%"}
                       height={"auto"}
                       src={require("./Assets/gummies-logo.mp4")}
                       playsInline={true}
                       onCanPlay={() => playVid()}
                       preload={"auto"}
                />
                {/*   <button onClick={playVid}>Play</button>
*/}
                {/*<ReactPlayer
                    //loop={true}
                    width={"100%"}
                    height={"auto"}
                    //playing={true}
                    url={require("./Assets/gummies-logo.mp4")}  />*/}
                {/* <   Player autoPlay={true} preload={"auto"}>
                    <source src={require("./Assets/gummies-logo.mp4")} />
                </Player>*/}
                <Flex className={"row"}  style={{
                }}>
                    <Box className={"header"} marginRight={"12px"}>
                        Gummies on Solana
                    </Box>
                    <Flex style={{width: "48px"}}>
                        <img src={require("./Assets/Solana_logo.png")} width={"100%"} height={"auto"}/>
                    </Flex>
                </Flex>

                <Flex className={"row"}  style={{
                }}>
                    <Box className={"header"} marginRight={"12px"}>
                        Tokenomics: soon
                    </Box>
                </Flex>


                <Flex className={"row"}  style={{
                    flex:1
                }}>
                    <Box className={"header"} marginRight={"12px"}>
                        Contract Address: soon
                    </Box>
                </Flex>


                <Footer />
            </Flex>

        </Flex>
    );
}

export default App;
