import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ParallaxProvider} from "react-scroll-parallax";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import theme from "./theme";

const customTheme = extendTheme(theme);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ChakraProvider theme={customTheme}>
            <ParallaxProvider>
                <App/>
            </ParallaxProvider>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
