import React from "react";
import {Box, Flex, Link} from "@chakra-ui/react";

const Footer = () => {

    return (
        <Flex style={{
            flexDirection: "column",
            //justifyContent: "space-between",
            //alignItems:"center",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "12px",
            paddingRight: "12px",
            height: "120px",
            backgroundColor: "rgba(250,252,255, 0.033)",
        }}>

            <Flex style={{
                fontWeight: "900",
                alignContent: "center",
                justifyContent: "space-between",
                paddingTop: 60
            }}>

                <Link href={"https://twitter.com/gummiescoin"} target={"_blank"} style={{width:"22px", marginRight: "12px"}}><img width={"100%"} height={"auto"} src={require("../Assets/x.png")} /></Link>
                <Link href={"https://instagram.com/gummiescoin"} target={"_blank"} style={{width:"22px"}}><img width={"100%"} height={"auto"} src={require("../Assets/insta.png")} /></Link>



            </Flex>
        </Flex>
    )
}

export default Footer