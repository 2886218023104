//https://github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src/foundations

import {BORDER_RADIUS, COLORS} from "./Constants";
//import React from "react";

const theme = {
  styles: {
    global: (props: any) => ({
      // styles for the `body`
      body: {
        backgroundColor: COLORS.bg,
        position: "relative",
        //color: "white",
        //height: "100vh",
        color: COLORS.fontColor,
      },
      html: {
        //height: "100vh",
      },

      // styles for the `a`
      a: {
        color: "white",
        fontWeight: 800,
        _hover: {
          textDecoration: "none",
        },
      },
      '.header': {
        fontSize: 24,
        fontWeight: 900,
        //color: "white",
        textShadow:"0 7px 14px rgba(250, 250, 193, 0.1), 0 3px 6px rgba(0, 0, 0, 0.18)",
        //boxShadow: "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.18)",
      },
      '.row': {
        justifyContent: "center",
        paddingTop: "36px",
        textAlign: "center",
        alignItems:"center",
        marginVertical: "24px",
      },
      '.section': {
        margin: "0.15em",
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "1em",
        paddingRight: "1em",
        borderRadius: BORDER_RADIUS,
        border: `1px solid ${COLORS.borderColor}`,
      },
      '.sidebar': {
        border: `1px solid ${COLORS.borderColor}`,
        background: COLORS.sidebar,
        margin: "0.1em",
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "0.25em",
        paddingRight: "0.251em",
        borderRadius: BORDER_RADIUS,
      },
      ".mainPanel": {
        paddingTop: "0.618em",
        paddingBottom: "0.618em",
        //paddingLeft: "1em",
        //paddingRight: "1em",
        border: `1px solid ${COLORS.borderColor}`,
        borderRadius: BORDER_RADIUS,
        flexDirection: "column",
        overflow: "auto",
        flex: 1,
        minHeight: "min-content",
        margin: "0.15em",
      },
      ".mainPanelTitle": {
        fontSize: "0.92em",
        fontWeight: 900,
        marginLeft: "1em",
        marginRight: ".5em",
        marginBottom: "0.3em",
        marginTop: "0.618em",
        alignItems: "center",
      },
      ".mainPanelSubTitle": {
        fontSize: "xs",
        fontWeight: 900,
        //marginLeft: "1em",
        //marginRight: ".5em",
        //marginBottom: "0.618em",
      },
      ".listItem": {
        flexDirection: "column",
        overflow: "hidden",
        // flex: 1,
        paddingLeft: "0.618em",
        paddingRight: "0.618em",
        paddingBottom: "0.1em",
        paddingTop: "0.1em",
        //border: `1px solid ${COLORS.borderColorLight}`,
        cursor: "pointer",
        marginTop: "0.1em",
        marginBottom: "0.1em",
        marginLeft: "0.1em",
        marginRight: "0.1em",
        background: "rgba(0,3,6,0.12)",
        borderRadius: BORDER_RADIUS,
        _hover: {
          background: `rgba(200, 254, 203, 0.1)`,
        }
      },
      ".listItemActive": {
        background: `rgba(10, 5, 0, 0.15)`,
        border: `1px solid ${COLORS.secondaryColorAlpha}`
      },
      ".listItemTitle": {
        flexDirection: "column",
        overflow: "hidden",
        // flex: 1,
        paddingLeft: "0.618em",
        paddingRight: "0.618em",
        paddingBottom: "0.1em",
        paddingTop: "0.1em",
        //border: `1px solid ${COLORS.borderColorLight}`,
        cursor: "pointer",
        marginTop: "0.1em",
        marginBottom: "0.1em",
        marginLeft: "0.1em",
        marginRight: "0.1em",
        background: "none",
        borderRadius: BORDER_RADIUS,

      },
      ".card": {
        flexDirection: "column",
        overflow: "hidden",
        //flex: 1,
        paddingLeft: "1.618em",
        paddingRight: "1.618em",
        paddingBottom: "1.618em",
        paddingTop: "1.618em",
        border: `1px solid ${COLORS.borderColor}`,
        cursor: "pointer",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        bg: "rgba(200,230,220,0.02)",
        borderRadius: BORDER_RADIUS,
        boxShadow: ' 8px 8px 14px 0 rgba(0, 0, 0, 0.2),  inset -8px -8px 14px 0 rgba(0, 0, 0, 0.10),  inset 8px 8px 14px 0 rgba(255, 255, 255, 0.032) ',
      },
      ".chartAxis": {
        fill: COLORS.secondaryColor
      },
      ".slider": {
        appearance: "none",
        //color:"green",
        fontWeight: 'bold',
        textAlign: "center",
        width: "100%",
        border: "none",
        background: COLORS.bgFlat,
        opacity: 0.7,
        transition: `opacity ".2s"`,
      },
      ".slider:hover": {
        opacity: 1,
      },
      ".slider::-webkit-slider-thumb": {
        appearance: "none",
        width: "1em",
        height: "1em",
        borderRadius: "50%",
        background: COLORS.secondaryColor,
        cursor: "pointer",
      }
      ,
      ".slider::-moz-range-thumb": {
        width: "1em",
        height: "1em",
        background: "#04AA6D",
        cursor: "pointer",
      },


      /*
      .slider {
  -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 3px;
        background: rgb(240, 241, 242);
        outline: none;
        opacity: 0.7;
      -webkit-transition: 0.2s;
transition: opacity 0.2s;
border: none;
border-radius: 0.1em;
}
       */
    }),
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    //heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    //body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    // body: "Montserrat",
    //mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontSizes: {
    "3xs": "0.423em",
    "2xs": "0.618em",
    xs: "0.72em",
    sm: "0.82em",
    md: "1em",
    lg: "1.218em",
    xl: "1.318em",
    "2xl": "1.409em",
    "3xl": "1.718em",
    "4xl": "2.309em",
    "5xl": "2.420em",
    "6xl": "3.309em",
    "7xl": "4.5em",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },
  //https://github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src/components
  components: {
    Alert: {
      baseStyle: {
        container: {
          borderRadius: "0.2em",
          color: "rgb(0,0,0)"
        },
      },
    },
    AlertTitle: {
      baseStyle: {
        //color: "rgb(0,0,0)"
      }
    },
    Drawer: {
      baseStyle: {
        dialog: {},
      },
    },
    Modal: {
      baseStyle: {
        overlay: {
          bg: 'blackAlpha.600', //change the background
        },
        dialog: {
          borderRadius: 'md',
          bg: COLORS.bg,
        },
        /*dialog: {
          backgroundColor: COLORS.bgFlat,
        },*/
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            paddingY: "0.2em",
            border: `1px solid rgba(250,252,253,0.3)`,
            //  borderColor: `rgba(255, 250, 250, 0.4)`,
          },
        },
      },
    },
    FormError: {
      baseStyle: {
        color: "rgb(200,0,0)",
        background: "rgb(200,0,0)",
      },
    },
    FormLabel: {
      baseStyle: {
        //color: "rgb(0,0,0)",
        fontSize: "sm",
        marginBottom: "1"
      },
    },

    Button: {
      // 1. We can update the base styles
      baseStyle: {
        //outline: "none",
        color: "rgba(252,253,254,0.65)",
        /*background: "rgb(1,2,3)",
                boxShadow: `0 0 8px 4px ${COLORS.shadow}`,
                marginY: "0.25em",*/
        //paddingY:20,

        _focus: {
          //borderColor: "rgba(9,9,9,0)",
          outline: "none",
          boxShadow: "none",
        },
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xs: {
          paddingX: "0.1em",
          height: ".1em",
        },
        sm: {
          paddingX: "0.1em",
          height: "1em",
        },
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },

      // 3. We can add a new visual variant
      /*ghost: variantGhost,
            outline: variantOutline,
            solid: variantSolid,
            link: variantLink,
            unstyled: variantUnstyled,*/
      variants: {
        small: {
          padding: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
        // 4. We can override existing variants
        outline: () => ({
          color: "rgba(252,253,254,0.65)",
          bg: `rgba(10, 5, 0, 0.2)`,
          fontWeight: 400,
          fontSize: "0.85em",
          height: "1.85em",
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          //borderColor: `rgba(255, 250, 245, 0.2)`,
          marginY: .5,
          _hover: {
            bg: "rgba(200,100,100,0.2)",
          },
        }),
        outlineActive: () => ({
          color: "rgba(255,250,250,1)",
          bg: COLORS.buttonActive,
          fontWeight: 900,
          fontSize: "0.85em",
          height: "1.85em",
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          marginY: .5,
          borderColor: `rgba(255, 250, 245, 0.8)`,
          _hover: {
            // bg: "rgba(200,100,100,0.2)",
          },

        }),
        delete: () => ({
          color: "rgba(252,253,254,0.65)",
          bg: `rgba(100, 5, 0, 0.3)`,
          fontWeight: 400,
          fontSize: "0.85em",
          height: "1.85em",
          paddingY: 2,
          borderRadius: 4,
          borderColor: `rgba(255, 250, 245, 0.2)`,
          _hover: {
            bg: "rgba(200,100,100,0.2)",
          },
        }),

        solid: (props: { colorMode: string }) => ({
          color: "rgba(252,253,254,0.65)",
          bg: `rgba(10, 5, 0, 0.1)`,
          borderRadius: 6,
          _hover: {
            bg: "rgba(100,110,120,0.2)",
          },
          _active: {
            bg: `rgba(10, 5, 0, 0.15)`,
            border: `1px solid ${COLORS.secondaryColorAlpha}`
          },
        }),
        submit: (props: { colorMode: string }) => ({
          color: "rgba(252,253,254,0.65)",
          bg: `rgba(10, 5, 0, 0.1)`,
          borderRadius: 6,
          boxShadow: `0 1px 14px ${COLORS.secondaryColorAlpha}, 1px 2px 6px ${COLORS.secondaryColorAlpha}, 0 -4px 8px ${COLORS.secondaryColorAlpha}`,
          _hover: {
            bg: "rgba(100,110,120,0.2)",
          },
          _active: {
            bg: `rgba(10, 5, 0, 0.15)`,
            border: `1px solid ${COLORS.secondaryColorAlpha}`
          },
        }),
        submitPrimary: (props: { colorMode: string }) => ({
          color: "rgba(252,253,254,0.65)",
          bg: `rgba(10, 5, 0, 0.1)`,
          borderRadius: 6,
          boxShadow: `0 1px 14px ${COLORS.primaryColorAlpha}, 1px 2px 6px ${COLORS.primaryColorAlpha}, 0 -4px 8px ${COLORS.primaryColorAlpha}`,
          _hover: {
            bg: "rgba(100,110,120,0.2)",
          },
          _active: {
            bg: `rgba(10, 5, 0, 0.15)`,
            border: `1px solid ${COLORS.secondaryColorAlpha}`
          },
        }),
      },
    },
  },
};

export default theme;
