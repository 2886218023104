import {UseToastOptions} from "@chakra-ui/react";

export const POLLING_NODE_DELAY = 6000
export const POLLING_WALLET_DELAY = 6000
export const NAVBAR_HEIGHT = "4.2em";
export const STATUSBAR_HEIGHT = "1.2em";
export const BORDER_RADIUS = 6

export const TOAST_POSITION = 'bottom'
export const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  isClosable: true,
  position: TOAST_POSITION,
  variant: "subtle",
  duration: 3000,
  /*render: () => (

        <Box color='white' p={3} bg='blue.500'>
            Hello World
        </Box>
    ),    */
  //variant: "subtle",
}
export const COLORS = {
  fontColor: "rgba(250,250,255,0.818)",
  fontColorStrong: "rgba(250,252,255,0.923)",
  fontColorLight: "rgba(250,250,255,0.4)",
  primaryColor: `rgb(49, 176, 193)`,
  primaryColorLight: `rgba(49, 176, 193, 0.82)`,
  primaryColorAlpha: `rgb(49, 176, 193, 0.618)`,
  secondaryColor: `rgb(175, 38, 79)`,
  secondaryColorAlpha: `rgba(175, 38, 79, 0.618)`,
  secondaryColorLight: `rgba(253, 201, 220, .8)`,
  //bg: 'rgb(12,18,19)',
  //bg: 'linear-gradient(-27deg, rgb(6,10,9), rgb(11,43,47))',
  //bg: 'linear-gradient(-27deg, rgb(6,10,9), rgb(13,34,39))',
  bg: `rgb(4, 8, 12)`, //'rgb(6,10,9)', //,
  bgFlat: `rgb(240, 238, 241)`, //'rgb(6,10,9)', //,
  buttonActive: 'rgba(239,241,242,0.9)',
  borderColor: 'rgba(239,251,242,0.12)',
  borderColorLight: 'rgba(239,251,242,0.0618)',
  sidebar: 'rgba(13,22,18,0.26)',
  upColor: `rgb(49, 176, 193)`,//`rgb(49, 176, 193)`,
  downColor: `rgb(175, 38, 79)`,//`rgb(175, 38, 79)`,
  ema1Color: "rgba(236,246,255,0.2)",
  ema2Color: `rgb(49, 176, 193, 0.2)`,
  //ema3Color: "rgba(106,173,236,0.618)",
  ema3FillColor: "rgba(106,173,236,0.1618)",
  volumeColor: `rgba(175, 38, 79, 0.62)`,
}

export const labelColor = COLORS.fontColor;
export const labelBGDark = "rgba(23,18,10,1)";
export const axisColor = "rgba(250,243,235,0.5)";
export const axisColorLight = "rgba(250,243,235,0.15)";
