import React from "react";

import {Box, Flex, Link} from "@chakra-ui/react";

const Header = () => {

    return (
        <Flex style={{

            justifyContent: "space-between",
            alignItems:"center",
            paddingTop:"6px",
            paddingBottom:"6px",
            paddingLeft: "12px",
            paddingRight: "12px",
        }}>
            {/*<Flex style={{fontWeight:"900"}}>
                Gummies
            </Flex>*/}
            <Box style={{width:"100px", marginRight: "12px"}}><img width={"100%"} height={"auto"} src={require("../Assets/logo-8bit.png")} /></Box>

            <Flex
                style={{
                    alignContent:"center",

                }}
            >

                <Link href={"https://twitter.com/gummiescoin"} target={"_blank"} style={{width:"22px", marginRight: "12px"}}><img width={"100%"} height={"auto"} src={require("../Assets/x.png")} /></Link>
                <Link href={"https://instagram.com/gummiescoin"} target={"_blank"} style={{width:"22px"}}><img width={"100%"} height={"auto"} src={require("../Assets/insta.png")} /></Link>
            </Flex>
        </Flex>
    )
}

export default Header